












































import { Vue, Component, Prop } from 'vue-property-decorator'
import { DateTime } from 'luxon'

import FilesList from '@/components/FilesList.vue'
import LabelDataRow from '@/components/LabelDataRow.vue'
import TaskPracticeQuestion from '@/components/views/exercise/mentor/TaskPracticeQuestion.vue'
import { EducationLargeTaskResource, ExerciseLargeResource, ExerciseMasterResource, TaskType } from '@/store/types'
import { convertSecondsToHoursMinutes } from '@/utils/functions'
import { downloadOriginalFile } from '@/utils/services/config'

@Component({
  components: {
    FilesList,
    LabelDataRow,
    TaskPracticeQuestion,
  },
})
export default class ExercisesItemTaskMasterInformation extends Vue {
  @Prop({ required: true })
  private groupId!: number

  @Prop({ required: true })
  private exercise!: ExerciseLargeResource

  @Prop({ required: true })
  private task!: EducationLargeTaskResource

  @Prop({ required: true })
  private master!: ExerciseMasterResource

  private get isCreative () {
    return this.task.type.value === TaskType.CREATIVE
  }

  private get isPractice () {
    return this.task.type.value === TaskType.PRACTICE
  }

  private get taskUUID() {
    return this.$route.params.taskUUID
  }

  private get answerKeys() {
    const task = this.exercise.tasks.find(task => task.uuid === this.taskUUID)
    if (task) {
      return task.answerMedia
    }

    return []
  }

  private get time() {
    const startTimestamp = this.task.workStartedAt ? (DateTime.fromSQL(this.task.workStartedAt, { zone: 'Europe/Moscow' }) as any).ts / 1000 : 0
    const endTimestamp = this.task.onCheckAt ? (DateTime.fromSQL(this.task.onCheckAt, { zone: 'Europe/Moscow' }) as any).ts / 1000 : 0
    const duration = endTimestamp - startTimestamp
    return {
      duration: this.exercise.avgExecutionSeconds ? convertSecondsToHoursMinutes(duration, true) : '',
      recommend: this.exercise.avgExecutionSeconds ? convertSecondsToHoursMinutes(this.exercise.avgExecutionSeconds, true) : '',
    }
  }

  private get mediaList() {
    if (this.exercise && this.task)
      return this.exercise.subject.value !== 14 ? this.task.media : this.task.media.map(media => ({
        ...media,
        url: `${downloadOriginalFile}${media.id}`,
      }))
    return []
  }

  private get answerMediaList() {
    if (this.exercise && this.task)
      return this.exercise.subject.value !== 14 ? this.answerKeys : this.answerKeys.map(media => ({
        ...media,
        url: `${downloadOriginalFile}${media.id}`,
      }))
    return []
  }
}
